import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "file-uploader"
    }}>{`File uploader`}</h2>
    <ComponentCode name="File uploader" component="file-uploader" variation="file-uploader" hasReactVersion="fileuploader--fileuploader" hasVueVersion="fileuploader--default" hasAngularVersion="?path=/story/file-uploader--basic" codepen="MRyyyw" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "upload-states"
    }}>{`Upload states`}</h2>
    <ComponentCode name="Upload states" component="file-uploader" variation="file-uploader--example-upload-states" codepen="xeVVBW" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="file-uploader" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      